<div class="container" *ngIf="!isImage && !showWebcam">
    <div class="row">
        <div class="col-12 text-center pt-5">
            <img class="demo-img" src="./../../assets/images/demo.png">
        </div>
        <div class="col-12 text-center pt-3">
            <button class="btn btn-upload" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
        </div>
        <div class="col-12 text-center pt-2">
            <button class="btn btn-upload">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload Photo
            </button>
        </div>
    </div>
</div>

<div class="container" *ngIf="showWebcam">
    <div class="row">
        <div class="col-12 text-center pt-5">
            <div class="camera-div">
                <!-- <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                    [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                    [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
                    (initError)="handleInitError($event)"></webcam> -->
                <div style="resize: both; overflow: auto;" id="smart-cam-container"></div>
                <button *ngIf="started && allowManualCapture" class="btn btn-upload" (click)="triggerSnapshot()"
                    [disabled]="!isValid">CAPTURE IMAGE</button>
                <br />
            </div>
        </div>
    </div>
</div>

<div class="container web" *ngIf="isImage && !loading && !isError">
    <div class="row pt-5">
        <div class="col-4 text-center">
            <img class="data-img" [src]="url">
            <button class="btn btn-upload mx-auto mt-2" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
            <button class="btn btn-upload mt-1">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Change Photo
            </button>
        </div>
        <div class="col-8 shade-div p-5">
            <!-- <div class="row">
                <div class="col-6">
                    <h3>Dullness Score : <span class="skintone-text">{{ outputData.dullness_score}}</span></h3>
                </div>
                <div class="col-6">
                    <h3>Uneven Score : <span class="skintone-text">{{ outputData.uneven_score}}</span></h3>
                </div>
            </div> -->
            <div class="row">
                <div class="col-12">
                    <h3>Detected Skin Tone</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-2 pt-3 text-center">
                    <button class="btn-foundation" id="btn0"
                        [style.backgroundImage]="'url(' + outputImages.skin_tone_palette + ')'"
                        (click)="onClick($event)"></button>
                </div>
                <!-- <div class="col-10 pt-3 mx-auto">
                    <h4 class="skintone-text">{{ detectedSkintone }}</h4>
                </div> -->
            </div>
            <div class="row pt-5">
                <div class="col-12">
                    <h3>Recommended Foundation Shades</h3>
                    <h6>Click on the shade to try on</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-2 pt-3 text-center">
                    <button class="btn-foundation" id="btn1"
                        [style.backgroundImage]="'url(' + outputImages.palette1 + ')'"
                        (click)="onClick($event)"></button>
                    <br>
                    <div class="p-2"> {{outputData.recommendedTone1}} </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-12">
                    <h3>Recommended Product</h3>
                </div>
            </div>
            <div class="row justify-content-start">
                <div class="col-6 pt-3">
                    <div class="p-2 " style="font-size: 20px;">{{product.name}} </div>
                    <img class="product-foundation ml-2" src="{{product.image}}" />

                </div>
                <!-- <div class="col-6 pt-3">
                    <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                        <a href="https://yepoda.com/products/the-dewy-day?_gl=1*1i257ns*_up*MQ..*_ga*MTAyODU5NzU4Ny4xNzEzMjg3NjU5*_ga_8HGEPN91CS*MTcxMzI4NzY1Ny40LjAuMTcxMzI4NzY3OC4wLjAuMA..*_ga_ZZ8HGMGX10*MTcxMzI4NzY1OC4xLjEuMTcxMzI4NzY3OS4wLjAuMA"
                            target="_blank">
                            <button class="btn btn-upload mt-1">
                                <i class="fa fa-shopping-cart" aria-hidden="true"></i> Buy Now
                            </button>
                        </a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="container web" *ngIf="isError">
    <div class="row">
        <div class="col-12 text-center shade-div p-5">
            <h3 class="text-danger">{{ error }}</h3>
            <button class="btn btn-upload mx-auto mt-2" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
            <button class="btn btn-upload mt-1">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Change Photo
            </button>
        </div>
    </div>
</div>

<div class="container mobile" *ngIf="isImage && !loading && !isError">
    <div class="row shade-div p-2 m-1 mt-3">
        <!-- <div class="col-6">
            <h5>Dullness Score : <span class="skintone-text">{{ outputData.dullness_score}}</span></h5>
        </div>
        <div class="col-6">
            <h5>Uneven Score : <span class="skintone-text">{{ outputData.uneven_score}}</span></h5>
        </div> -->
        <div class="col-12">
            <h5>Detected Skin Tone</h5>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-center">
                    <button class="btn-foundation" id="btn0"
                        [style.backgroundImage]="'url(' + outputImages.skin_tone_palette + ')'"
                        (click)="onClick($event)"></button>
                </div>
                <!-- <div class="col-8 mx-auto">
                    <h5 class="skintone-text" style="margin: 20px;">{{ detectedSkintone }}</h5>
                </div> -->
            </div>
        </div>
    </div>
    <div class="row pt-1">
        <div class="col-12 text-center">
            <img class="data-img" [src]="url">
        </div>
    </div>
    <div class="row shade-div p-2 m-1 mt-2 text-center">
        <div class="col-12">
            <h4>Recommended Foundation Shades</h4>
            <h6>Click on the shade to try on</h6>
        </div>
        <!-- <div class="col-12 pt-3 text-center"> -->
        <div class="col-12 pt-3 text-center">
            <button class="btn-foundation" id="btn1" [style.backgroundImage]="'url(' + outputImages.palette1 + ')'"
                (click)="onClick($event)"></button>
            <br />
            <div class="p-2"> {{outputData.recommendedTone1}} </div>
        </div>

        <div class="col-12">
            <h3>Recommended Product</h3>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 pt-3 text-center">
                <div class="p-2 " style="font-size: 20px;">{{product.name}} </div>
                <img class="product-foundation ml-2" src="{{product.image}}" />
            </div>
            <!-- <div class="col-12 pt-3">
                <div
                    style="display: flex; flex-direction: row; justify-content: center; align-items: center; height: 100%;">
                    <a href="https://yepoda.com/products/the-dewy-day?_gl=1*1i257ns*_up*MQ..*_ga*MTAyODU5NzU4Ny4xNzEzMjg3NjU5*_ga_8HGEPN91CS*MTcxMzI4NzY1Ny40LjAuMTcxMzI4NzY3OC4wLjAuMA..*_ga_ZZ8HGMGX10*MTcxMzI4NzY1OC4xLjEuMTcxMzI4NzY3OS4wLjAuMA"
                        target="_blank">
                        <button class="btn btn-upload mt-1">
                            <i class="fa fa-shopping-cart" aria-hidden="true"></i> Buy Now
                        </button>
                    </a>
                </div>
            </div> -->
        </div>
    </div>
    <div class="row pt-1">
        <div class="col-12 text-center">
            <button class="btn btn-upload mx-auto mt-2 mobile" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
            <button class="btn btn-upload mt-1">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Change Photo
            </button>
        </div>
    </div>
</div>

<div class="container mobile" *ngIf="isError">
    <div class="row shade-div p-2 m-1">
        <div class="col-12 text-center">
            <h3 class="text-danger">{{ error }}</h3>
            <button class="btn btn-upload mx-auto mt-2 mobile" (click)="startWebcam()">
                <i class="fa fa-camera" aria-hidden="true"></i> Capture Photo
            </button>
            <button class="btn btn-upload mt-1">
                <input id="imgFile" type="file" (change)="changeImage($event)" accept="image/*">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i> Change Photo
            </button>
        </div>
    </div>
</div>

<div class="container" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-ui-loader></ngx-ui-loader>
        </div>
    </div>
</div>