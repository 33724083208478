import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FoundationService {

    constructor(private _http: HttpClient) { }

    checkFoundation(image) {
        
        const form = new FormData();
        form.append('image', image);
        const options = {
            reportProgress: true
        };
        return this._http.post(`${environment.apiUrl}/api/media/foundation/upload?clientkey=${environment.clientKey}`, form, options);
    }
}