import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FoundationService } from '@app/_shared/foundation.service';
import { first } from 'rxjs/operators';
// import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpErrorResponse } from '@angular/common/http';


declare global {
  interface Window {
    OrboSmartCapture: any;
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loading: boolean = false;
  isCamera: boolean = false;
  reader: FileReader = new FileReader;
  url: string = './../assets/images/demo.png';
  imgFile = undefined;
  isImage: boolean;
  outputData;
  outputImages;
  productData = [
    {
      skuCode: 1,
      name: "Lamel Oh My Clear Face Foundation Spf15 403 40ml",
      hexCode: "#e4bea0",
      image: "https://static.foyforyou.com/5060994130386_1.jpg"
    },
    {
      skuCode: 2,
      name: "Lamel Oh My Clear Face Foundation Spf15 404 40ml",
      hexCode: "#e6b692",
      image: "https://static.foyforyou.com/5060994130409_1.jpg"
    },
    {
      skuCode: 3,
      name: "Lamel Oh My Clear Face Foundation Spf15 405 40ml",
      hexCode: "#d8a57f",
      image: "https://static.foyforyou.com/5060994130942_1.jpg"
    },
    {
      skuCode: 4,
      name: "Lamel Oh My Clear Face Foundation Spf15 407 40ml",
      hexCode: "#cf9061",
      image: "https://static.foyforyou.com/5060994130980_1.jpg"
    },
    {
      skuCode: 5,
      name: "Lamel Oh My Clear Face Foundation Spf15 408 40ml",
      hexCode: "#c07f4e",
      image: "https://static.foyforyou.com/5060994131000_1.jpg"
    },
    {
      skuCode: 6,
      name: "Lamel Oh My Clear Face Foundation Spf15 406 40ml",
      hexCode: "#d6946b",
      image: "https://static.foyforyou.com/5060994130966_1.jpg"
    },
    {
      skuCode: 7,
      name: "Type Beauty Inc Decrease Serum Foundation For Fine Lines And Wrinkles Spf 50, Pa ++++, 30Ml, Oatmeal",
      hexCode: "#d4a067",
      image: "https://static.foyforyou.com/8906155670322_1.jpg"
    },
    {
      skuCode: 8,
      name: "Type Beauty Inc Decrease Serum Foundation For Fine Lines And Wrinkles Spf 50, Pa ++++, 30Ml,Frappe",
      hexCode: "#db9358",
      image: "https://static.foyforyou.com/8906155670339_1.jpg"
    },
    {
      skuCode: 9,
      name: "Type Beauty Inc Decrease Serum Foundation For Fine Lines And Wrinkles Spf 50, Pa ++++, 30Ml,Biscotti",
      hexCode: "#d58c57",
      image: "https://static.foyforyou.com/8906155670346_1.jpg"
    },
    {
      skuCode: 10,
      name: "Type Beauty Inc Decrease Serum Foundation For Fine Lines And Wrinkles Spf 50, Pa ++++, 30Ml,Nutmeg",
      hexCode: "#d68254",
      image: "https://static.foyforyou.com/8906155670353_1.jpg"
    },
    {
      skuCode: 11,
      name: "Type Beauty Inc Decrease Serum Foundation For Fine Lines And Wrinkles Spf 50, Pa ++++, 30Ml,Peanut",
      hexCode: "#b6743a",
      image: "https://static.foyforyou.com/8906155670445_1.jpg"
    },
    {
      skuCode: 12,
      name: "Type Beauty Inc Get Even, Serum Foundation For Dark Spots And Dullness Spf 50, Pa++++-30Ml,Oatmeal",
      hexCode: "#d19f6e",
      image: "https://static.foyforyou.com/8906155670544_1.jpg"
    },
    {
      skuCode: 13,
      name: "Type Beauty Inc Get Even, Serum Foundation For Dark Spots And Dullness Spf 50, Pa++++-30Ml,Latte",
      hexCode: "#d88d54",
      image: "https://static.foyforyou.com/8906155670582_1.jpg"
    },
    {
      skuCode: 14,
      name: "Type Beauty Inc Get Even, Serum Foundation For Dark Spots And Dullness Spf 50, Pa++++-30Ml,Cookie",
      hexCode: "#c4864b",
      image: "https://static.foyforyou.com/8906155670605_1.jpg"
    },
    {
      skuCode: 15,
      name: "Type Beauty Inc Get Even, Serum Foundation For Dark Spots And Dullness Spf 50, Pa++++-30Ml,Walnut",
      hexCode: "#c98449",
      image: "https://static.foyforyou.com/8906155670629_1.jpg"
    },
    {
      skuCode: 16,
      name: "Type Beauty Inc Get Even, Serum Foundation For Dark Spots And Dullness Spf 50, Pa++++-30Ml,Cider",
      hexCode: "#b5743c",
      image: "https://static.foyforyou.com/8906155670681_1.jpg"
    },
    {
      skuCode: 17,
      name: "Type Beauty Inc Matte Up Serum Foundation For Oily And Acne Prone Skin Spf 50, Pa++++, 30Ml,Biscotti",
      hexCode: "#d3a067",
      image: "https://static.foyforyou.com/product_image_TYP_6_2024_01_30_11_47_32_1.jpg"
    },
    {
      skuCode: 18,
      name: "Type Beauty Inc Matte Up Serum Foundation For Oily And Acne Prone Skin Spf 50, Pa++++, 30Ml,Cookie",
      hexCode: "#c5874c",
      image: "https://static.foyforyou.com/8906155670841_1.jpg"
    },
    {
      skuCode: 19,
      name: "Type Beauty Inc Matte Up Serum Foundation For Oily And Acne Prone Skin Spf 50, Pa++++, 30Ml,Cider",
      hexCode: "#b3723a",
      image: "https://static.foyforyou.com/8906155670926_1.jpg"
    },
    {
      skuCode: 20,
      name: "Lamel Foundation Stay Matte 30ml,401-Porcelain",
      hexCode: "#e2c0a3",
      image: "https://static.foyforyou.com/5060586635725_1.jpg"
    },
    {
      skuCode: 21,
      name: "Lamel Foundation Stay Matte 30ml,402-Beige",
      hexCode: "#e8b98b",
      image: "https://static.foyforyou.com/5060586635749_1.jpg"
    },
    {
      skuCode: 22,
      name: "Lamel Foundation Stay Matte 30ml,404-Sandy",
      hexCode: "#e9bc9f",
      image: "https://static.foyforyou.com/5060586635787_1.jpg"
    },
    {
      skuCode: 23,
      name: "Lamel Smart Skin Serum Tinted Foundation 35ml,401-Porcelain",
      hexCode: "#d9baa8",
      image: "https://static.foyforyou.com/5060805942566_1.jpg"
    },
    {
      skuCode: 24,
      name: "Lamel Smart Skin Serum Tinted Foundation 35ml,402-Beige",
      hexCode: "#debea9",
      image: "https://static.foyforyou.com/5060805942580_1.png"
    },
    {
      skuCode: 25,
      name: "Lamel Smart Skin Serum Tinted Foundation 35ml,403-Ivory",
      hexCode: "#dab799",
      image: "https://static.foyforyou.com/5060805942603_1.png"
    },
    {
      skuCode: 26,
      name: "Lamel Smart Skin Serum Tinted Foundation 35ml,404-Sand",
      hexCode: "#d0ad8f",
      image: "https://static.foyforyou.com/5060805942627_1.jpg"
    },
    {
      skuCode: 27,
      name: "Lamel Smart Skin Serum Tinted Foundation Spf30+ 35ml,405 Latte",
      hexCode: "#d19e78",
      image: "https://static.foyforyou.com/5060994130508_1.jpg"
    },
    {
      skuCode: 28,
      name: "Lamel Smart Skin Serum Tinted Foundation Spf30+ 35ml,406 Medium Beige",
      hexCode: "#c09268",
      image: "https://static.foyforyou.com/5060994130522_1.jpg"
    },
    {
      skuCode: 29,
      name: "Lamel Smart Skin Serum Tinted Foundation Spf30+ 35ml,408 Honey",
      hexCode: "#c48558",
      image: "https://static.foyforyou.com/5060994130560_1.jpg"
    },
    {
      skuCode: 30,
      name: "Lamel Smart Skin Serum Tinted Foundation Spf30+ 35ml,409 Deep Beige",
      hexCode: "#a28e7f",
      image: "https://static.foyforyou.com/5060994131147_1.jpg"
    },
    {
      skuCode: 31,
      name: "L.A. Girl PRO Coverage HD FoundationFair",
      hexCode: "#b6926e",
      image: "https://static.foyforyou.com/81555966423_1.jpg"
    },
    {
      skuCode: 32,
      name: "L.A. Girl PRO Coverage HD FoundationNatural",
      hexCode: "#9b7859",
      image: "https://static.foyforyou.com/081555966447_1.jpg"
    },
    {
      skuCode: 33,
      name: "Maybelline New York Super Stay Lumi-Matte Liquid Foundation - 120(35ml)",
      hexCode: "#ffc89a",
      image: "https://images-static.nykaa.com/media/catalog/product/e/3/e372c756902395970071_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 34,
      name: "Maybelline New York Super Stay Lumi-Matte Liquid Foundation - 115(35ml)",
      hexCode: "#fed7ba",
      image: "https://images-static.nykaa.com/media/catalog/product/e/3/e372c756902395970057_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 35,
      name: "Maybelline New York Super Stay Lumi-Matte Liquid Foundation - 123(35ml)",
      hexCode: "#fdc185",
      image: "https://images-static.nykaa.com/media/catalog/product/e/3/e372c756902395970095_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 36,
      name: "Maybelline New York Super Stay Lumi-Matte Liquid Foundation - 125(35ml)",
      hexCode: "#f2bb91",
      image: "https://images-static.nykaa.com/media/catalog/product/e/3/e372c756902395970101_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 37,
      name: "Maybelline New York Super Stay Lumi-Matte Liquid Foundation - 128(35ml)",
      hexCode: "#ebb47e",
      image: "https://images-static.nykaa.com/media/catalog/product/e/3/e372c756902395970118_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 38,
      name: "M.A.C Studio Radiance Serum-Powered Foundation - NC20(30ml)",
      hexCode: "#e0ad77",
      image: "https://images-static.nykaa.com/media/catalog/product/d/4/d407182773602656691_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 39,
      name: "M.A.C Studio Radiance Serum-Powered Foundation - NC25(30ml)",
      hexCode: "#d3a574",
      image: "https://images-static.nykaa.com/media/catalog/product/d/4/d407182773602656707_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 40,
      name: "M.A.C Studio Radiance Serum-Powered Foundation - NC30(30ml)",
      hexCode: "#d39d6b",
      image: "https://images-static.nykaa.com/media/catalog/product/d/4/d407182773602656721_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 41,
      name: "M.A.C Studio Radiance Serum-Powered Foundation - NC35(30ml)",
      hexCode: "#d39d6b",
      image: "https://images-static.nykaa.com/media/catalog/product/d/4/d407182773602656738_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 42,
      name: "M.A.C Studio Radiance Serum-Powered Foundation - NC37(30ml)",
      hexCode: "#d19c72",
      image: "https://images-static.nykaa.com/media/catalog/product/d/4/d407182773602656745_1.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 43,
      name: "Estee Lauder Double Wear Stay-In-Place Makeup Mini Foundation with SPF 10 - 1W2 Sand(15ml)",
      hexCode: "#d3ae81",
      image: "https://images-static.nykaa.com/media/catalog/product/f/7/f727990887167507135_2301241.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 44,
      name: "Estee Lauder Double Wear Stay-In-Place Makeup Mini Foundation with SPF 10 - 4N1 Shell Beige(15ml)",
      hexCode: "#b07d45",
      image: "https://images-static.nykaa.com/media/catalog/product/f/7/f727990ESTEE00000004_2301241.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 45,
      name: "Estee Lauder Double Wear Stay-In-Place Makeup Mini Foundation with SPF 10 - 2W2 Rattan(15ml)",
      hexCode: "#d1a367",
      image: "https://images-static.nykaa.com/media/catalog/product/f/7/f727990887167507128_2301241.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 46,
      name: "Estee Lauder Double Wear Stay-In-Place Makeup Mini Foundation with SPF 10 - 3W1 Tawny(15ml)",
      hexCode: "#c1925a",
      image: "https://images-static.nykaa.com/media/catalog/product/f/7/f727990887167507104_2301241.jpg?tr=w-344,h-344,cm-pad_resize"
    },
    {
      skuCode: 47,
      name: "Estee Lauder Double Wear Stay-In-Place Makeup Mini Foundation with SPF 10 - 3W2 Cashew(15ml)",
      hexCode: "#be9052",
      image: "https://images-static.nykaa.com/media/catalog/product/f/7/f727990887167507111_2301241.jpg?tr=w-344,h-344,cm-pad_resize"
    }
  ]

  product;
  isError: boolean = false;
  error;
  public skintones = ['', 'Classic Beige', 'Fair Porcelain', 'Fair Ivory', 'Porcelain', 'Natural Ivory', 'Ivory', 'Light Beige', 'Classic Ivory', 'Creamy Beige', 'Soft Sand', 'Nude Beige', 'Warm Nude', 'Buff Beige', 'Natural Beige', 'True Beige', 'Soft Tan', 'Natural Buff', 'Pure Beige', 'Rich Tan', 'Light Honey', 'Sun Beige', 'Golden', 'Natural Tan', 'Warm Honey', 'Toffee', 'Golden Caramel', 'Warm Sun', 'Classic Tan', 'Spicy Brown', 'Cappuccino', 'Coconut', 'Warm Coconut', 'Latte', 'Mocha', 'Truffle', 'Nutmeg', 'Deep Golden', 'Deep Bronze', 'Java', 'Espresso'];
  // detectedSkintone;
  ogImage;


  started = false;
  allowManualCapture = false;
  isValid = false;

  // toggle webcam on/off
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 375},
    height: { ideal: 460 }
  };
  // public errors: WebcamInitError[] = [];

  // latest snapshot
  // public webcamImage: WebcamImage = <any>'./../assets/skin_analysis.gif';

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();


  constructor(private _foundationService: FoundationService, private _ngxService: NgxUiLoaderService, private cdr: ChangeDetectorRef) {
    window.OrboSmartCapture.default.initialize();
  }

  ngOnInit(): void {
    // WebcamUtil.getAvailableVideoInputs()
    //   .then((mediaDevices: MediaDeviceInfo[]) => {
    //     this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    //   });
    this._ngxService.start();
  }

  private startSmartCapture() {
    window.OrboSmartCapture.default.startCamera(true,
      (img: string) => this.onAutoCapture(img),
      () => {
        this.allowManualCapture = true;
      },
      (isValid: boolean) => {
        this.isValid = isValid;
      },
      (cause) => {
        this.isImage = false;
        this.showWebcam = false;
        this.started = false;
        window.OrboSmartCapture.default.stopCamera();
        document.getElementById("smart-cam-container")!.innerHTML = "";
        this.allowManualCapture = false;
        console.log(cause)
        alert('Unable to start camera. Make sure you have working camera and permissions for the same.');
      })
      .then(({ container, start }: { container: Element, start: () => {} }) => {
        this.started = true;
        document.getElementById("smart-cam-container")?.append(container);
        start();

      })
      .catch((err) => {
        alert("Error while starting smart capture");
      })
      .finally(() => {
        this.isStartInprogress = false;
      });
  }

  blobUrl = "";
  onAutoCapture(img: string) {
    this.showWebcam = false;
    this.blobUrl = img;
    this.url = this.blobUrl;
    this.ogImage = this.url;
    this.blobUrl = "";
    this.started = false;
    window.OrboSmartCapture.default.stopCamera();
    document.getElementById("smart-cam-container")!.innerHTML = "";
    this.allowManualCapture = false;
    this.handleImage();
  }

  isStartInprogress = false;
  startWebcam() {
    this.isImage = false;
    this.showWebcam = true;

    this.cdr.detectChanges();

    if (this.isStartInprogress) {
      return;
    }
    this.isStartInprogress = true;
    this.startSmartCapture();

  }

  public triggerSnapshot(): void {
    this.showWebcam = false;
    // this.trigger.next();
    this.blobUrl = window.OrboSmartCapture.default.capture();
    this.url = this.blobUrl;
    this.ogImage = this.url;
    this.blobUrl = "";
    this.started = false;
    window.OrboSmartCapture.default.stopCamera();
    document.getElementById("smart-cam-container")!.innerHTML = "";
    this.allowManualCapture = false;
    this.handleImage();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  // public handleInitError(error: WebcamInitError): void {
  //   this.errors.push(error);
  // }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(): void {
    // Naming the image
    const byteString = atob(this.url.split(',')[1]);
    const mimeString = this.url.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });

    // Naming the image
    // console.log(blob);
    const date = new Date().valueOf();
    let text = '';
    const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
    }
    // Replace extension according to your media type
    const imageName = date + '.' + text + '.jpeg';

    // Create File object from Blob
    this.imgFile = new File([blob], imageName, { type: 'image/jpeg' });

    this.isImage = true;
    this.checkFoundation();

  }

  public cameraWasSwitched(deviceId: string): void {
    // console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }


  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  changeImage(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.imgFile = event.target.files[0];

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = (<FileReader>event.target).result as string;
        this.ogImage = this.url;
      };
      this.isImage = true;
      this.checkFoundation();

    }
  }

  onClick(e) {
    switch (e.target.id) {
      case 'btn0': {
        this.url = this.ogImage;
        break;
      }
      case 'btn1': {
        this.url = this.outputImages.found1;
        break;
      }
      case 'btn2': {
        this.url = this.outputImages.found2;
        break;
      }
      case 'btn3': {
        this.url = this.outputImages.found3;
        break;
      }
      case 'btn4': {
        this.url = this.outputImages.found4;
        break;
      }
      default: return;
    }
    this.cdr.detectChanges();
  }

  checkFoundation() {
    this._ngxService.start();
    this.loading = true;
    this.isError = false;
    this._foundationService.checkFoundation(this.imgFile)
      .pipe(first()).subscribe(data => {
        // console.log(data);
        this._ngxService.stop();
        this.loading = false;
        this.outputData = data['outputData'];
        this.outputImages = data['outputImages'];
        //Object.assign(this.outputImages,{found1Name:'MediumBrown Neutral 6'},{found2Name:'MediumBrown Neutral 6'},{found3Name:'MediumBrown Neutral 6'},{found4Name:'MediumBrown Neutral 6'})
        // this.detectedSkintone = this.skintones[this.outputData.skin_tone_type]
        this.product = this.productData.find(item => item.skuCode === this.outputData.recommendedTone1);
        this.cdr.detectChanges();
      },
        (err: HttpErrorResponse) => {
          this._ngxService.stop();
          this.isError = true;
          this.error = err.error.error.message;
          // console.log(err.error.error.message);
          this.cdr.detectChanges();
        });
  }

}
